import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import SanityLink from '~components/SanityLink'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'

const Page = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PageHeader>
				<Subtitle>{page.title}</Subtitle>
			</PageHeader>
			<Section>
				<Text>
					<div>
						<RichText content={page.text} />
					</div>
				</Text>
			</Section>
			<Section>
				{page?.states?.map(state => (
					<State key={state?.title}>
						<h5>{state?.title}</h5>
						<Stockists>
							{state?.stockists?.map(button => 
								<SanityLink key={button?.text} link={button.link}>
									{button.text}
								</SanityLink>
							)}
						</Stockists>
					</State>
				))}
			</Section>
			<Section>
				<ExtraInfo>
					<p>{page?.extraInfoTitle}</p>
					<div>
						<RichText content={page.extraInfo} />
					</div>
				</ExtraInfo>
			</Section>
			<DataViewer data={page} name="page"/>
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}

const State = styled.div`
	max-width: 672px;
	width: 100%;
	margin: 0 auto;
	grid-column: 2/12;
	margin-Bottom: var(--xxxl);
	${mobile}{
		grid-column: span 12;
	}
	h5{
		font-style: italic;
		margin-bottom: var(--s);
	}
`

const Stockists = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 10px;
	column-gap: 20px;
	a{
		font-size: 21px;
		&:hover{
			text-decoration: underline;
		}
	}
`

const ExtraInfo = styled.div`
	max-width: 672px;
	width: 100%;
	margin: 0 auto;
	grid-column: 2/12;
	margin-Bottom: var(--xxxl);
	${mobile}{
		grid-column: span 12;
	}
	> p{
		font-style: italic;
	}
	div{
		font-size: 21px;
	}
`


const Text = styled.div`
	max-width: 672px;
	width: 100%;
	margin: 0 auto;
	grid-column: 2/12;
	margin-Bottom: var(--xxxl);
	font-style: italic;
	h4{
		font-family: 'Masqualero', serif;
		font-size: 26px;
		line-height: 1.25;
		letter-spacing: -0.17px;
		text-transform: none;
		${tablet}{
			font-size: 22px;
		}
	}
	ol, ul{
		margin-top: var(--xxl);
		margin-bottom: var(--xl);
	}
	li {
		font-size: 21px;
		margin-left: 1.4em;
    padding-left: 0.2em;
	}

	${mobile}{
		grid-column: span 12;
	}
`

export const query = graphql`
  query StockistsPageQuery($slug: String) {
    page: sanityStockistsPage(slug: {current: {eq: $slug}}) {
      title
			text: _rawText(resolveReferences: {maxDepth: 8})
			states {
				title 
				stockists {
					...button
				}
			}
			extraInfoTitle
			extraInfo: _rawExtraInfo(resolveReferences: {maxDepth: 8})
		}
  }
`

export default Page